body {
  background-color: #f1f1f1;
}
.font-nanum {
  font-family: 'NanumSquare', sans-serif;
}
.navbar-show {
  visibility: visible;
  transition: all 100ms ease-in;
  transform: none;
}
.navbar-hide {
  visibility: hidden;
  transition: all 200ms ease-out;
  transform: translate(0, -100%);
}
.spinner-wrap {
  position: relative;
  width: 30px;
  padding-top: 30px;
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: #07d;
  animation: spinner 0.6s linear infinite;
}
