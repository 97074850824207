@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);

@tailwind base;
@tailwind components;

.bg-purple {
  background-color: #9b26b6;
}
.bg-purple a.navlink {
  @apply flex-1 w-full h-full flex items-center justify-center text-white font-medium;
}
.bg-purple a.navlink:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.bg-purple .active {
  background-color: rgba(255, 255, 255, 0.2);
}
#bgVideo {
  z-index: -10;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
@tailwind utilities;
